<template>
  <div  style="margin-top:15px" class="md-layout md-alignment-top-space-between auth-panel md-elevation-5">
    <div class="md-layout-item md-size-50 login-form">
      <md-card class="md-elevation-0">
        <form novalidate @submit.prevent="validateLogin">
          <md-card-header>
            <div class="md-title">{{ $t('login.title') }}</div>
            <div class="small-login-logo login-title">
              <img alt="wibki logo" :src="`${publicPath}wibki.png`">
            </div>
          </md-card-header>

          <md-card-content class="fields">
            <md-field :class="[getValidationClass('username'),'md-field-auth']">
              <md-input name="username"
                        id="username"
                        :autocomplete="$t('login.username')"
                        v-model="form.username"
                        :disabled="isLoading"
                        @focus="clearErrors('username')"
                        class="md-input-auth input-with-border"
                        placeholder="Email or Username"/>
              <div class="md-error" v-if="!$v.form.username.required">
                {{ $t('login.requiredField') }}
              </div>
            </md-field>

            <md-field :class="[getValidationClass('password'),'md-field-auth']">
              <input name="password"
                     id="password"
                     type="password"
                     :autocomplete="$t('login.password')"
                     v-model="form.password"
                     :disabled="isLoading"
                     class="md-input-auth-password input-with-border"
                     placeholder="Password"/>
              <div class="md-error" v-if="!$v.form.password.required">
                {{ $t('login.requiredField') }}
              </div>
              <div class="md-error" v-else-if="!$v.form.password.minlength">
                {{ $t('login.minLengthPassword') }}
              </div>
            </md-field>

            <div class="forgot-links">
              <md-checkbox class="remember-box" v-model="form.remember">Remember Me</md-checkbox>
              <router-link class="forgot-link" tag="div" :to="{ name: 'userForgotPassword' }">
                <a>{{ $t('login.forgotPassword') }}</a>
              </router-link>
            </div>

            <div class="error-message" v-for="error in errors.username" :key="error">{{ error }}</div>
            <div class="error-message" v-for="error in errors.password" :key="error">{{ error }}</div>
          </md-card-content>

          <md-progress-bar class="md-accent" md-mode="indeterminate" v-if="isLoading"/>

          <md-card-actions class="login-options">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <md-button type="submit" class="md-raised md-accent md-size-4x btn-full-width login-auth-btn"
                           :disabled="isLoading">
                  {{ $t('login.loginBtn') }}
                </md-button>
              </div>

              <div class="md-layout-item md-size-100  social-auth">
                <div class="md-layout">
                  <div class="md-layout-item md-size-100 ">
                    <p>OR</p>
                  </div>

                  <div class="md-layout-item md-size-100">
                    <md-button class="md-icon-button md-raised md-primary google-btn"
                               :disabled="isLoading" @click="socialLogin('google')">
                      <img :src="`${publicPath}google-icon.svg`">
                    </md-button>
                    <md-button class="md-icon-button md-raised md-accent facebook-btn"
                               :disabled="isLoading" @click="socialLogin('facebook')">
                      <img :src="`${publicPath}facebook-icon.svg`">
                    </md-button>
                    <md-button class="md-icon-button md-raised md-primary apple-btn"
                               :disabled="isLoading" @click="appleAlert()">
                      <img :src="`${publicPath}apple-icon.svg`" width="100%">
                    </md-button>
                  </div>

                  <div class="md-layout-item md-size-100">
                    <p class="register-login-labels">{{ $t('login.noAccount') }}
                      <router-link tag="span" :to="{ name: 'userRegister' }">
                        <a>{{ $t('login.getStarted') }}</a>
                      </router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </md-card-actions>
        </form>
      </md-card>
    </div>

    <div class="md-layout-item md-size-50 right-section">
      <h1 class="large-text">
        What<br>
        will<br>
        you<br>
        <span class="pink-text">explore</span>
        today?
      </h1>
    </div>
    <div>
      <md-dialog-confirm
        :md-active.sync="first"
        md-title="SignUp via Apple Login notice!"
        md-content="In the Apple Login Process, <b>Please Share the email -> like in image from below</b>. <br>
        Why? Because if you don't share the e-mail and you already have an active account, we cannot link the active account to apple account.<br>
        Thank You!
        <br><br>
          <img src='https://wibki.com/SignInApple.png' height='500' width='500'>"
        md-confirm-text="Agree"
        md-cancel-text="Disagree"
        @md-confirm="socialLogin('sign-in-with-apple')"/>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  minLength,
} from 'vuelidate/lib/validators';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import loaderMixin from '@/mixins/loaderMixin';
import { LOGIN, SOCIAL_LOGIN } from '@/store/modules/auth/authActions.type';

export default {
  name: 'UserLogin',
  mixins: [
    validationMixin,
    formHelpersMixin,
    loaderMixin,
  ],
  data: () => ({
    form: {
      username: null,
      password: null,
      remember: false,
    },
    first: false,
    publicPath: process.env.BASE_URL,
  }),
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
        minLength: minLength(5),
      },
    },
  },
  methods: {
    clearForm() {
      this.$v.$reset();
      this.form.username = null;
      this.form.password = null;
    },
    validateLogin() {
      this.$v.$touch();
      this.errors = {};

      if (!this.$v.$invalid) {
        this.loginAction();
      }
    },
    appleAlert() {
      this.first = true;
    },
    socialLogin(provider) {
      this.$store.dispatch(SOCIAL_LOGIN, provider)
        .then((response) => {
          this.$log.info(SOCIAL_LOGIN, provider);
          window.location.href = response.data.url;
        })
        .catch((error) => {
          this.$log.error(SOCIAL_LOGIN, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'VALIDATION_ERROR' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
    loginAction() {
      const payload = {
        username: this.form.username,
        password: this.form.password,
      };
      this.$store.dispatch(LOGIN, payload)
        .then((response) => {
          this.$log.info(LOGIN, payload);
          // this.$router.push('/');
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.clearForm();
        })
        .catch((error) => {
          this.$log.error(LOGIN, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.message.includes('Confirm your account')) {
            this.$router.push(`confirm-email/${this.form.username}`);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/common/auth/auth-panel.scss';
  @import '@/assets/scss/common/auth/login-responsive.scss';
</style>
